.loading-main-container {
  height: 100%;
  width:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anticon-loading {
  font-size: 3em;
}