.match-results-main-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  height: auto;
  text-align: left;
  padding: 0 30px;

  &.mobile {
    padding: 15px;
    text-align: center;
  }
}

.match-results-input{
  width: 250px;
  margin: 20px 0;
  .ant-input {
    width: 250px;
    background-color: transparent;
    border: none;
    margin: 10px 0 20px;
    border-radius: 0;
    border-bottom: .5px solid $secondaryColor;

    &:focus, &:active, &:hover {
      outline:  !important;
      box-shadow: none;
      border-bottom: .5px solid $lightGrey;
    }
    
  }

  .anticon.anticon-search {
    color: $secondaryColor;
  }

  &.mobile {
    width: 60%;
    margin: 20px 0 0;
    .ant-input {
      width: 100%;
    }
  }
}

.match-results-input-clear {
  &.anticon {
    color: $secondaryColor;
  }
}