.standing-item-container {
  width: 100%;
  height: auto;
  margin: 30px auto 10px;
  background-color: $white;
  color: $primaryColor;
  text-align: center;
  box-shadow: 0px 0px 10px 1px rgba(156,154,156,1);
}

.standing-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.standing-item-avatar {
  background-color: $primaryColor;
  color: $white;
  font-size: 1.15em;
  width: 40px;
  height: 40px;
  line-height:40px;
  position: absolute;
  left: -20px;
  top: -20px;

  &.mobile {
    left: 0px;
    top: 0px;
    border-radius: 0;
    width: 35px;
    height: 30px;
    line-height:30px;
    font-size: 1em;
    font-weight: bolder;
  }
}

.standing-item-office {
  background-color: $secondaryColor;
  color: $lightGrey;
  font-size: .75em;
  position: absolute;
  right: 0px;
  top: 0px;
}

.standing-item-name {
  font-size: 1.5em;
  text-align: center;
  font-weight: bold;
  margin: 5px 0 0;

  &.mobile {
    margin: 20px 0 0;
    font-weight: bolder;
  }
}

.standing-item-record {
  font-size: 1.3em;
  font-weight: lighter;
  text-align: center;
  margin: 10px 0px;

  &.mobile {
    margin: 0;
    font-size: 1.25em;
    font-weight: normal;
  }
}

.standing-results-container {
  display: flex;
  width: 100%;
  align-items: center;
  background-color: $grey;

  &.mobile {
    display: inline-block;
  }
}

.standing-results-content {
  width: 25%;
  padding: 5px 0px;
  font-size: .75em;
  font-weight: lighter;

  &:not(:last-child) {
    border-right: 2px solid $lightGrey;
  }

  &.mobile {
    width: 50%;
    display: inline-block;
    font-size: .9em;
    border: .5px solid $lightGrey;
  }
}

.standing-results-item {
  font-size: 1.25em;
  font-weight: lighter;
  text-transform: uppercase;

  &.mobile {
    font-weight: normal;
  }
}

.standing-prize-title {
  font-size: 14px;
  font-weight: lighter;
  span {
    font-weight: bolder;
  }
  &.total {
    margin-top: 10px;
    padding-top: 8px;
    border-top: 1px solid #001529;
  }
}

.standing-popover-button {
  position: relative;
  height: auto;
  padding: 3px 10px;
}

.standing-badge {
  position: absolute;
  top: -15px;
  right: -12px;
  padding: 2px 5px;
  border-radius: 5px;
  background-color: #001529;
  font-size: 12px;
  color: $white;
}