$headerHeight: 65px;

body {
  margin: 0;
  padding: 0;
}

.app-main-container {
  display: flex;
  max-width: 100vw;
  min-width: 100vw;
  min-height: 100vh;
  text-align: center;
}

.app-main-wrapper {
  height: 100%;
  display: flex;
  background: $grey;
}

.app-component-container {
  width: 100%;
  min-height: 100%;
  height: auto;
}

.app-header {
  height: $headerHeight;
  max-height: $headerHeight;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.mobile {
    justify-content: space-between;
    &.ant-layout-header {
      padding: 0 20px;
    }
  }
}

.app-avatar {
  height: 40px;
  width: 40px;
  line-height: 40px;
  background-color: $secondaryColor;

  .anticon {
  }
}

.app-logo {
  width: 65px;
  height: 65px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;

  &.mobile {
    width: 40px;
    height: 40px;
  }
}

.input-error {
  border-color: $red !important;
}

.app-header-menu {
  color: $white;
  font-size: 1.5em;
}

.app-footer {
  text-align: center;
  color: $primaryColor;
  border-top: 1px solid #e8e8e8;
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }

  .app__container {
    margin-top: 50px;
    margin-bottom: -50px;
    height: auto;
    max-height: unset;
  }

  .app__main-container {
    margin: 0px;
  }
}