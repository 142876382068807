$primaryColor: #001529;
$secondaryColor: #1890ff;
$white: #fff;
$black: #000;
$grey: #f0f2f5;
$lightGrey: lighten($grey, 20%);
$darkGrey: darken($grey, 20%);
$green: #50c878;
$red: #ff4040;
$yellow: #fcc201;
