.match-result-item-container {
  height: auto;
  padding: 15px 20px;
  margin: 0px auto;
  background-color: $white;
  color: $primaryColor;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $primaryColor;
  position: relative;

  &:last-child {
    border-bottom: none;
  }

  &.mobile {
    display: block;
    padding: 20px 10px 5px;
    text-align: center;
  }
}

.match-result-item-date {
  background-color: $secondaryColor;
  color: $lightGrey;
  font-size: .75em;
  padding: 5px 15px;
  position: absolute;
  right: 0px;
  top: 0px;

  &.mobile {
    font-size: .8em;
    font-weight: bolder;
    padding: 2px 8px;
  }
}

.match-result-item {
  text-align: center;
  width: 33%;
  padding: 10px 0;

  &:not(:last-child) {
    border-right: .5px solid $grey;
  }

  &.mobile {
    width: 100%;
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 50px;
  }
}

.match-result-item-title {
  font-size: .8em;
  font-weight: lighter;

  &.mobile {
    font-size: 1.15em;
    font-weight: normal;
  }

}

.match-result-item-content {
  font-size: 1.25em;

  &.mobile {
    font-size: 1.15em;
    font-weight: bolder;
  }
}