body {
  font-size: 16px;
  font-weight: 500;
  color: $primaryColor;
}

h1 {
  color: $primaryColor;
}
h2 {
  color: $primaryColor;
}
h3 {
  color: $primaryColor;
}
h4 {
  color: $primaryColor;
}
h5 {
  color: $primaryColor;
}
h6 {
  color: $primaryColor;
}
p {
  color: $primaryColor;
}
