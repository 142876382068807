.standings-main-container {
  width: 100%;
  height: auto;
  text-align: left;
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 30px;

  &.mobile {
    padding: 15px;
    text-align: center;
  }
}

.standings-input-clear {
  &.anticon {
    color: $secondaryColor;
  }
}

.standings-filter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.standings-filter {
  width: 125px;
  margin: 0 10px;
}