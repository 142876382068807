.leagues-sider {
  width: 200px;
  min-height: calc(100vh - 65px);
  max-height: calc(100vh - 65px);
  background-color: $grey;
}

.leagues-sider-menu {
  background-color: $grey;
  min-height: 100%;

  &.mobile {
    border-right: none;
    background-color: transparent;
  }

  .ant-collapse-item {
    text-align: left;
  }

  .ant-collapse-content {
    background-color: $white;
  }

  .ant-collapse-content-box {
    padding: 0;
  }
}

.leagues-sider-menu-item {
  background-color: $white;
  text-align: left;
  padding: 10px 15px;
  &.mobile {
    background-color: transparent;
  }
  &.active {
    background-color: $secondaryColor;
    color: $white;
  }
}

.leagues-content-container {
  width: 75%;
  height: auto;
  margin: 0 auto;
}

.leagues-drawer {
  min-height: 100vh;
  max-height: 100vh;
}

.leagues-drawer-handle {
  position: fixed;
  font-size: 1.5em;
  top: 65px;
  left: 0px;
  z-index: 100;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}

.leagues-drawer-back {
  font-size: 1.5em;
}

.ant-drawer-body {
  padding: 0px;
}