.signup-main-container {
  display: block;
  margin: 0 auto;
  width: 100%;
  &.mobile {
    margin: 0 auto;
  }
}

.signup-form-container {
  width: 100%;
  max-width: 500px;
  background-color: $white;
  border-radius: 5px;
  padding: 20px 0px;
  margin: 0px auto;
  &.mobile {
    margin: 0px auto;
    padding: 15px;
    max-width: 100%;
  }
}

.signup-form-item {
  text-align: center;
  display: flex;
  align-items: center;justify-content: center;
  .ant-input, .ant-select {
    width: 250px;
    margin: 10px;
  }

  &.mobile {
    .ant-input, .ant-select {
      width: 200px;
      margin: 10px;
    }
  }
}

.signup-form-span {
  width: 100px;
}

.signup-submit {
  background-color: $secondaryColor;
  color: $white;
  margin: 15px;
  &:hover, &:visited, &:focus {
    background-color: $secondaryColor;
    color: $white;
  }
}
.ant-tabs-bar {
  margin: 0;
}

.signup-tab {
  margin-top: 50px;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background-color: $secondaryColor;
  color: $white;
}

.error {
  &.ant-input, &.ant-select {
    border: 1px solid red;
  }
  color: red;
}