.report-match-main-container {
  width: 100%;
  padding: 20px 0px;
  text-align: center;
  min-height: 100vh;
}

.report-match-input {
  width: 50%;
}

.report-match-form-container {
  background-color: $white;
  max-width: 500px;
  padding: 40px 20px;
  margin: 0 auto;
}

.report-match-form-row {
  width: 100%;
  margin: 0 auto;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report-match-form-label {

}

.report-match-form-submit {
  margin: 20px auto;

  button {
    background-color: $secondaryColor;
    color: $white;
    &:hover, &:visited, &:focus {
      background-color: $secondaryColor;
      color: $white;
    }
  }
}