.home-main-container {
  width: 100%;
  height: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content-title {
  font-size: 45px;
  text-transform: uppercase;
  color: $primaryColor;
  font-weight: bold;
}