.overall-standings-main-container {
  height: 100%;
  margin: 10px auto 0px;
  max-width: 1000px;
  width: 100%;
}

.overall-standings-wrapper {
  width: 100%;
  overflow: auto;
  max-width: 100vw;
}

.overall-standings-league-records-title {
  text-align: center;
  font-weight: bold;
}

.overall-standings-search-container {
  width: 100%;
  max-width: 300px;
  text-align: left;

  &.mobile {
    width: 250px;
    max-width: none;
  }
}

.overall-standings-table-container {
  width: 100%;
  .ant-table {
    overflow-x: auto;
  }
}

.overall-standings-league-records-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.overall-standings-league-record {
    margin: 10px;
}

.overall-standings-results-input{
  width: 100%;
  margin: 20px 0;
  .ant-input {
    width: 100%;
    background-color: transparent;
    border: none;
    margin: 10px 0 20px;
    border-radius: 0;
    border-bottom: .5px solid $secondaryColor;

    &:focus, &:active, &:hover {
      outline:  !important;
      box-shadow: none;
      border-bottom: .5px solid $lightGrey;
    }

  }

  .anticon.anticon-search {
    color: $secondaryColor;
  }

  &.mobile {
    width: 100%;
    margin: 20px 0 0;
    .ant-input {
      width: 100%;
    }
  }
}

.overall-standings-input-clear {
  &.anticon {
    color: $secondaryColor;
  }
}